<template lang="pug">
  div
    .p-4
      .container
        stack
          stack-item(fill)
            .h3.mb-0 {{ $t('companies.tabs.features') }}
        data-table(
          :embed="true",
          :progress="inProgress",
          :headers="headers",
          :items="tableItems",
          ref="companyFeaturesTable",
          :disablePagination="true"
        )
          template(slot="cel_is_enabled", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.is_enabled",
              switch,
              @change="changeEnabled(item)"
            )
          template(slot="cel_settings", slot-scope="{ item }")
            template(v-if="item.value === 'dimensions_dependence'")
              div
                b-form-group(
                  :label="$t(`companies.features.dimension_number`, {number: 1})",
                  :invalid-feedback="errors.first('dimension_1')"
                  :state="errors.has('dimension_1') ? false : null"
                  label-cols="4"
                )
                  company-dimension-names-select(
                    :companyId="$route.params.id"
                    v-model="item.settings.dimension_1"
                    v-validate="{required: item.is_enabled}"
                    data-vv-name="dimension_1"
                    :data-vv-as="$t(`companies.features.dimension_number`, {number: 1})",
                    :disabled="!item.is_enabled"
                    @input="changeDimension"
                  )
                b-form-group(
                  :label="$t(`companies.features.dimension_number`, {number: 2})",
                  :invalid-feedback="errors.first('dimension_2')"
                  :state="errors.has('dimension_2') ? false : null"
                  label-cols="4"
                )
                  company-dimension-names-select(
                    :companyId="$route.params.id"
                    v-model="item.settings.dimension_2"
                    v-validate="{required: item.is_enabled}"
                    data-vv-name="dimension_2"
                    :disabled="!item.is_enabled"
                    :data-vv-as="$t(`companies.features.dimension_number`, {number: 2})",
                    @input="changeDimension"
                  )
                .d-flex.justify-content-end(v-if="dimensionFeatureChanged && item.is_enabled")
                  b-btn(
                    variant="primary"
                    squared
                    :disabled="dimensionFeatureInProgress"
                    @click="saveDimensions"
                  ) {{ $t('actions.save') }}
            template(v-else-if="Object.entries(item.settings).length !== 0")
              b-form-checkbox(
                v-for="(value, name) in item.settings",
                :key="name",
                v-if="['cost_centers_set', 'prioritize_keywords_methods', 'prioritize_vendors_methods'].includes(name)",
                v-model="item.settings[name]",
                switch,
                @change="changeEnabled(item)"
              ) {{ $t(`companies.features.${name}`) }}
            template(v-else)
              p None
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteReport(item)",
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
        .p(v-if="automationFeatureChanged")
          div.d-flex.justify-content-center
            div.alert.alert-warning
              .mdi.mdi-alert-box
                | {{ $t(`companies.features.automation_feature_reminder`) }}
          div.d-flex.justify-content-center
            stack
              stack-item(fill)
                b-form-group(
                  label-for="bic-input",
                  label-cols="0",
                  :invalid-feedback="errors.first('comment')"
                  :state="errors.has('comment') ? false : null"
                )
                  b-input(
                    v-model="invoiceGuesserFeature.comment",
                    v-validate="'required'"
                    data-vv-name="comment"
                    :data-vv-as="$t('companies.features.changes_comment')"
                    placeholder="Changes comment"
                  )
              stack-item
                b-btn(variant="primary", squared, @click="changeSettings(null)")
                  .mdi.mdi-account-check-outline
                  | {{ $t('actions.save') }}

        .form-container.mt-5
          h5 {{ $t('companies.features.settings') }}
          b-form-group(
            :label="$t(`companies.features.automate_accounting`)",
            label-for="automate-accounting-input",
            label-cols="4"
          )
            b-form-checkbox#automate-accounting-input(
              v-model="company.automate_accounting",
              switch
            )
          b-form-group(
            :label="$t(`companies.features.deduct_vat`)",
            label-for="deduct-vat-input",
            label-cols="4"
          )
            b-form-checkbox#deduct-vat-input(
              v-model="company.deduct_vat",
              switch
            )
          b-form-group(
            :label="$t(`companies.features.overwrite_suggestions`)",
            label-for="overwrite-suggestions-input",
            label-cols="4"
          )
            b-form-checkbox#overwrite-suggestions-input(
              v-model="company.overwrite_suggestions",
              switch
            )
          b-form-group(
            :label="$t(`companies.features.voucher_date`)",
            label-for="voucher-date-input",
            label-cols="4"
          )
            b-form-checkbox#voucher-date-input(
              v-model="invoiceGuesserFeature.settings.voucher_date",
              switch
            )
          b-form-group(
            :label="$t('companies.features.comment_user')",
            label-for="comment-user-input",
            label-cols="4"
          )
            b-input#comment-user-input(
              v-model="invoiceGuesserFeature.comment_user",
            )
          b-form-group(
            :label="$t('companies.basic.iban')",
            label-for="iban-input",
            label-cols="4"
          )
            b-input#iban-input(v-model="company.iban")

          b-form-group(
            :label="$t('companies.basic.bic')",
            label-for="bic-input",
            label-cols="4"
          )
            b-input#bic-input(v-model="company.bic")
          template(v-if="showScope")
            h5 {{$t('companies.features.scope_invoice_number_starting_symbols')}}
            data-table(
              :headers="invoicesStartingSymbolsHeaders",
              :items="invoicesStartingSymbols",
              disablePagination
            )
              template(#cel_symbol="{item, index}")
                b-form-input(
                  :value="invoicesStartingSymbols[index].symbol"
                  @input="updateSymbol(index, $event)"
                  maxlength="1"
                  v-validate="'required'"
                  :name="`symbol.${index}.symbol`"
                  :state="errors.first(`symbol.${index}.symbol`) ? false : null"
                )
              template(#cel_count="{item, index}")
                b-form-input(
                  v-model.number="invoicesStartingSymbols[index].count"
                  step="1"
                  type="number"
                  @keypress="strictNumberInput($event)"
                  v-validate="'required'"
                  :name="`symbol.${index}.number`"
                  :state="errors.first(`symbol.${index}.number`) ? false : null"
                )
              template(#cel_actions="{index}")
                b-btn(
                  variant="flat-danger"
                  squared
                  size="xs"
                  @click="deleteSymbol(index)"
                ) 
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
              template(#empty_title) 
                div
              template(#empty_description)
                div
                  p {{$t('companies.features.no_symbols')}}
                  p
                    b-btn.add-symbol-button(
                      variant="flat-primary"
                      squared
                      size="xs"
                      @click="addSymbol()"
                    )
                      .mdi.mdi-plus
                      | {{$t('companies.features.add_symbol')}}
            b-btn.add-symbol-button(
              v-if="invoicesStartingSymbols.length"
              variant="flat-primary"
              squared
              size="xs"
              @click="addSymbol()"
            )
              .mdi.mdi-plus
              | {{$t('companies.features.add_symbol')}}
          stack
            stack-item(fill)
            stack-item
              b-btn(variant="primary", squared, @click="changeSettings(null)")
                .mdi.mdi-account-check-outline
                | {{ $t('actions.save') }}
</template>

<script>
import ApiCompanyFeatures from "@/api/companyFeatures.js";
import ApiCompanies from "@/api/companies.js";
import CompanyDimensionNamesSelect from "@/components/CompanyDimensionNamesSelect.vue";
import ApiCompanyReports from "@/api/companyReports";

export default {
  components: {
    CompanyDimensionNamesSelect,
  },

  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      inProgress: false,
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "name",
          label: "Name",
          squeeze: false,
        },
        {
          key: "is_enabled",
          label: "Enabled",
          squeeze: true,
        },
        {
          key: "settings",
          label: "Settings",
          squeeze: false,
        },
      ],
      items: [],
      settings: {},
      invoiceGuesserFeature: {},
      company: {},
      invoicesStartingSymbols: [],
      matchFile: null,
      automationFeatureChanged: false,
      dimensionFeatureChanged: false,
      dimensionFeatureInProgress: false,
      showScope: false,
    };
  },

  computed: {
    isDimensionsDependenceAvailable() {
      return this.items.some((feature) => {
        return feature?.settings?.cost_centers_set ?? false;
      });
    },

    tableItems() {
      return this.items.filter((item) => {
        if (item.value === "dimensions_dependence") {
          return this.isDimensionsDependenceAvailable;
        }
        return true;
      });
    },

    invoicesStartingSymbolsHeaders() {
      return [
        {
          key: "symbol",
          label: this.$t("companies.features.symbol"),
          width: "50%",
        },
        {
          key: "count",
          label: this.$t("companies.features.count"),
          width: "50%",
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ];
    },
  },

  methods: {
    getData() {
      this.inProgress = true;

      const featuresRequest = ApiCompanyFeatures.fetchFeatures(
        this.$route.params.id
      ).then((resp) => {
        this.items = resp.data.features;
        this.invoiceGuesserFeature = this.items.filter(
          (f) => f.value == "invoice_guesser"
        )[0];
        this.invoiceGuesserFeature.settings.prioritize_keywords_methods =
          this.invoiceGuesserFeature.settings.prioritize_keywords_methods ||
          false;
        this.invoiceGuesserFeature.settings.prioritize_vendors_methods =
          this.invoiceGuesserFeature.settings.prioritize_vendors_methods ||
          false;
      });

      const companyRequest = ApiCompanies.getCompany(
        this.$route.params.id
      ).then((resp) => {
        this.company = resp.data.company;
        this.invoicesStartingSymbols = Object.entries(
          resp.data.company.invoices_starting_symbols
        ).map(([symbol, count]) => ({
          symbol,
          count,
        }));
      });

      Promise.all([featuresRequest, companyRequest])
        .then(() => {
          this.inProgress = false;
        })
        .catch((e) => {
          this.inProgress = false;
        });
    },

    changeEnabled(item) {
      if (item.id != this.invoiceGuesserFeature.id) {
        ApiCompanyFeatures.updateFeature(this.$route.params.id, item.id, {
          feature: { is_enabled: item.is_enabled },
        }).then((resp) => {
          item = resp.data.feature;
        });
      } else {
        this.automationFeatureChanged = true;
      }
    },

    changeSettings(item) {
      this.$validator.validateAll().then((res) => {
        if (res === false) {
          return;
        }

        let params = {
          feature: this.invoiceGuesserFeature,
        };
        let companyParams = {
          company: {
            automate_accounting: this.company.automate_accounting,
            deduct_vat: this.company.deduct_vat,
            iban: this.company.iban,
            bic: this.company.bic,
            overwrite_suggestions: this.company.overwrite_suggestions,
            invoices_starting_symbols:
              this.getSerializedInvoicesStartingSymbols(),
          },
        };
        if (item != null) {
          params.feature.settings = item.settings;
        }
        params.feature.comment = this.invoiceGuesserFeature.comment;
        ApiCompanyFeatures.updateFeature(
          this.$route.params.id,
          this.invoiceGuesserFeature.id,
          params
        ).then((resp) => {
          ApiCompanies.updateCompany(this.$route.params.id, companyParams).then(
            (resp) => {
              this.getData();
              this.$dialogAlert({
                title: this.$t("companies.features.settings_updated"),
                description: `<code>Change reason: ${this.invoiceGuesserFeature.comment}</code>`,
                variant: "primary",
                icon: "update",
              });
            }
          );
        });
        this.automationFeatureChanged = false;
      });
    },

    changeDimension() {
      this.dimensionFeatureChanged = true;
    },

    async saveDimensions() {
      this.dimensionFeatureInProgress = true;
      const valid = await this.$validator.validateAll();
      if (!valid) {
        this.dimensionFeatureInProgress = false;
        return;
      }
      const feature = this.items.find(
        (f) => f.value == "dimensions_dependence"
      );
      try {
        await ApiCompanyFeatures.updateFeature(
          this.$route.params.id,
          feature.id,
          { feature }
        );
        this.dimensionFeatureChanged = false;
      } catch (error) {
        // todo
      }
      this.dimensionFeatureInProgress = false;
    },
    showScopeReport() {
      ApiCompanyReports.showScopeReport(this.$route.params.id)
        .then((resp) => {
          this.showScope = resp.data.showScopeReport;
        })
        .catch((e) => {
          this.showScope = false;
        });
    },
    addSymbol() {
      this.invoicesStartingSymbols.push({ symbol: "", count: 0 });
    },
    deleteSymbol(index) {
      this.invoicesStartingSymbols.splice(index, 1);
    },
    updateSymbol(index, value) {
      this.invoicesStartingSymbols[index].symbol = value
        .replace(/[^a-zA-Z0-9]/)
        .slice(-1);
    },
    getSerializedInvoicesStartingSymbols() {
      const result = {};
      this.invoicesStartingSymbols.forEach((item) => {
        if (item.symbol) {
          result[item.symbol] = item.count;
        }
      });
      return result;
    },
    strictNumberInput(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getData();
    this.showScopeReport();
  },
};
</script>

<style scoped>
.add-symbol-button {
  margin-top: -20px;
}
</style>
